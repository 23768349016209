import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DriveTimeService } from '@services/drivetime/drivetime.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'delete-update-dialog',
  templateUrl: './delete-update-dialog.component.html',
  styleUrls: ['./delete-update-dialog.component.scss'],
})
export class DeleteUpdateDialogComponent implements OnInit {
  dataSource: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteUpdateDialogComponent>,
    public dialog: MatDialog,
    private userService: UserService,
    public driveTimeService: DriveTimeService,
  ) {
    this.dataSource = data.selectedTableData;
  }

  ngOnInit() {}

  apply() {
    const payload = this.dataSource.map((item) => {
      return {
        competitionID: item['id'],
        operationTypeID: item['operationTypeId'],
      };
    });
    console.log('delete comp payload', payload);
    this.driveTimeService.deleteMassUpdate(payload).subscribe(
      (res) => {
        console.log('Deleting  Record => res', res);
        this.getResponse(res);
      },
      (error) => {
        console.log('Deleting Record Error -> error', error);
        this.getResponse(error);
      },
    );
  }

  getResponse(resp) {
    this.dialogRef.close(resp);
  }
}
