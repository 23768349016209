import { Component, OnInit } from '@angular/core';
import { GTMService } from '@core/services/gtm.service';
import { environment } from '@env/environment';
import { AnalyticsService } from '@services/analytics.service';
import { MegamenuService } from '@services/megamenu.service';
import { TokenService } from '@services/token.service';
import { UserService } from '@services/user.service';
import { UserProfileService } from '@services/userProfile.service';
import { AuthType, init } from '@thoughtspot/visual-embed-sdk';
import { appRoutesMapping } from 'src/app/app-routing.module';
import { AccessPermission, FeatureAccessItemEnum, PermisisonObject } from 'src/app/models/userProfile.model';
import { v5 as uuidv5 } from 'uuid';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private userService: UserService,
    private tokenService: TokenService,
    private analyticsService: AnalyticsService,
    private userProfileService: UserProfileService,
    private gtmService: GTMService,
    private megaMenuService: MegamenuService) { }

  ngOnInit(): void {
    const generatedUuid = uuidv5(this.userService.user.email, '965b5ba2-1bc1-4dee-b68c-3e82a7f51c7e');
    this.analyticsService.uuid$$.next(generatedUuid);
    this.gtmService.addGtmToDom();
    this.initThoughtSpot();
  }
  private initThoughtSpot() {
    const thoughtSpotPages: PermisisonObject[] = [
      {
        accessItemName: FeatureAccessItemEnum.Supply,
        permission:  AccessPermission.VIEW 
      },
      {
        accessItemName: FeatureAccessItemEnum.Demand,
        permission:  AccessPermission.VIEW 
      },
      {
        accessItemName: FeatureAccessItemEnum.DownloadData,
        permission:  AccessPermission.VIEW 
      },
      {
        accessItemName: FeatureAccessItemEnum.BusinessEnvironment,
        permission:  AccessPermission.VIEW 
      },
    ]
    if (this.userProfileService.hasAny(thoughtSpotPages)) {
      this.initTS()
    }

  }

  initTS() {
    init({
      customizations: {
        style: {
          customCSS: {
            rules_UNSTABLE: {
              '[class="bk-powered-footer"]': {
                display: 'none',
              },
              '*': {
                color: '#262626',
                background: 'transparent',
              },
              '[class="highcharts-background"]': {
                fill: 'none',
              },
              '[class="highcharts-plot-background"]': {
                fill: 'none',
              },
              '.answer-content-module__compactVizContainer .answer-content-module__answerVizContainer': {
                border: '1px solid #003F2D26',
              },
            },
            variables: {
              '--ts-var-root-background': '#fafafa',
              '--ts-var-viz-box-shadow': 'none',
              '--ts-var-root-color': '#262626',
              '--ts-var-nav-color': '#262626',
              '--ts-var-search-bar-text-font-color': '#262626',
              '--ts-var-search-auto-complete-font-color': '#262626',
              '--ts-var-button--primary-color': '#262626',
              '--ts-var-button--secondary-color': '#262626',
              '--ts-var-button--tertiary-color': '#262626',
              '--ts-var-viz-title-color': '#262626',
              '--ts-var-viz-description-color': '#262626',
              '--ts-var-axis-data-label-color': '#262626',
              '--ts-var-viz-background': '#fafafa',
            },
          },
        },
      },
      username: environment.THOUGHTSPOT_USERNAME,
      autoLogin: true,
      thoughtSpotHost: 'https://cbre-dev.thoughtspot.cloud/#/',
      authType: AuthType.TrustedAuthToken,
      getAuthToken: () => {
        return fetch(`${environment.adminUrl}/thoughtspot/gettoken`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.tokenService.getAccessToken(),
          },
        })
          .then((response) => response.json())
          .then((data) => {
            return data.token;
          });
      },
    });
  }

}


