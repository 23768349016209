import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, filter, combineLatest, Subject, startWith, switchMap, shareReplay, map, tap } from 'rxjs';
import * as _ from 'lodash';

import { MegamenuService } from './megamenu.service';
import { BaseService } from './base.service';
import { AccessItem, AccessPermission, FeatureAccessItemEnum, PermisisonObject, UserProfileInfo, UserProfileRoleAccesses } from '../models/userProfile.model';
import { UserRoles } from '../drive-time/site-management/roles.model';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService extends BaseService {
  private url = environment.adminUrl;
  private header = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };

  private refresh$ = new Subject<void>();
  private userAccessV2$: Observable<UserProfileRoleAccesses>
  private userAccessSnapshot: UserProfileRoleAccesses;

  private userAccess$$ = new BehaviorSubject<UserProfileRoleAccesses>(null);
  userAccess$ = this.userAccess$$.asObservable();
  private userData$$ = new BehaviorSubject<UserProfileInfo>({});
  userData$ = this.userData$$.asObservable();
  private roles$$ = new BehaviorSubject<any>(null);
  roles$ = this.roles$$.asObservable();
  megamenuFlatItems;
  private mappedModules$$ = new BehaviorSubject<any>(null);
  mappedModules$ = this.mappedModules$$.asObservable();
  lastCalledAccessItem = {
    item: null,
    isEnabled: false,
  };

  constructor(override http: HttpClient, private router: Router, private megamenuService: MegamenuService) {
    super(http);
  }


  get userAccess(): UserProfileRoleAccesses {
    return this.userAccessSnapshot;
  }

  getAccessItem(itemName: string): AccessItem | undefined {
    return this.userAccess$$
      .getValue()
      ?.accessItems?.find((item) => item.itemName?.toLowerCase()?.includes(itemName?.toLowerCase()));
  }

   
  /* 
    * @param permissions - an array of permission objects
    * @returns - a boolean value
    */
  hasAny(permissions:  PermisisonObject[]): boolean {
    return permissions.some(({ permission, accessItemName }) => this.can(permission, accessItemName));
  }

  /*
   * @param permission - the permission to check
   * @param accessItemName - the name of the access item to check
   * @returns - a boolean value
   */
  can(permission: AccessPermission, accessItemName: string | FeatureAccessItemEnum): boolean {
    if (this.lastCalledAccessItem.item === accessItemName) return this.lastCalledAccessItem.isEnabled;

    const isEnabled = this.getAccessItem(accessItemName)?.[permission] === 'Enabled';

    // caching result so we don't need to go through array every time in getAccessItem method.
    this.lastCalledAccessItem.isEnabled = isEnabled;
    this.lastCalledAccessItem.item = accessItemName;

    return isEnabled;
  }

  getCurrentAccessItemName(url) {
    const currentAccessItem = this.mappedModules$$.getValue()?.[url?.split('?')[0]];

    return currentAccessItem?.itemName?.toLowerCase();
  }

  canViewTable(): boolean {
    const userAccess = this.userAccess$$.getValue();
    return userAccess.lagRoleId === UserRoles.ADMIN || userAccess.lagRoleId === UserRoles.SUPER_ADMIN;
  }

  canAddOrEdit(): boolean {
    return this.can(AccessPermission.ADD_EDIT, this.getCurrentAccessItemName(this.router.url));
  }

  canDownload(): boolean {
    return this.can(AccessPermission.DOWNLOAD_PRINT, this.getCurrentAccessItemName(this.router.url));
  }

  setUserData(data) {
  this.userData$$.next(data);
  }

  setRoles(data) {
    this.roles$$.next(data);
  }

  fetchInitialUserProfile() {
    this.getUserProfileData().subscribe((data) => {
      this.setUserData(data);
    });
  }

  fetchAllRoles() {
    this.getAllRoles().subscribe((data) => this.setRoles(data));
  }

  getUserAccess(): Observable<UserProfileRoleAccesses> {
    if (!this.userAccessV2$) {
      this.userAccessV2$ = this.refresh$.pipe(
        startWith(null),
        switchMap(() => this.getUser()),
        map((user) => {
          user.accessItems?.forEach((item) => {
            item.itemName = _.unescape(item.itemName);
          });
          return user;
        }),
        tap((acess) => this.userAccessSnapshot = acess),
        shareReplay(1)
      )
    }
    return this.userAccessV2$;
  }

  fetchUserAccesses() {
    this.getUser().subscribe((data: UserProfileRoleAccesses) => {
      data.accessItems?.forEach((item) => {
        item.itemName = _.unescape(item.itemName);
      });
      this.userAccess$$.next(data);
    });
    this.getMegamenuLinks();
  }

  getMegamenuLinks() {
    combineLatest([this.userAccess$, this.megamenuService.megaMenuData$])
      .pipe(filter((data: Array<any>) => data[0] !== null && data[1]?.length))
      .subscribe(([accessData, megamenuData]) => {
        this.megamenuFlatItems = this.megamenuService.getFlatMegamenuItems(megamenuData);

        accessData?.accessItems?.forEach((accessItem) => {
          const megamenuItem = this.megamenuFlatItems.find((i) => i.id === accessItem.itemId);

          // if parent has children, update their itemName to parent's.
          if (megamenuItem?.childItems?.length) {
            megamenuItem?.childItems?.forEach((childItem) => {
              this.megamenuFlatItems = this.megamenuFlatItems.map((i) => {
                if (i.id === childItem.id) {
                  return {
                    ...i,
                    itemName: megamenuItem?.itemName,
                  };
                }

                return i;
              });
            });
          }
        });

        this.mappedModules$$.next(
          this.megamenuFlatItems
            .map((item) => {
              if (item.itemURL?.startsWith('/')) {
                return { [String(item.itemURL)]: item };
              }

              return item;
            })
            .reduce((acc, next) => ({ ...acc, ...next }), {}),
        );
      });
  }

  getAllRoles(): Observable<any> {
    return this.http.get(`${this.url}/role`, this.header);
  }

  getUserProfileData(): Observable<UserProfileInfo> {
    return this.http.get<UserProfileInfo>(`${this.url}/manageuser`, this.header);
  }

  private getUser(): Observable<UserProfileRoleAccesses> {
    return this.http.get<UserProfileRoleAccesses>(`${this.url}/manageuser/user`, this.header);
  }

  saveUserProfile(data: UserProfileInfo): Observable<any> {
    return this.http.put(`${this.url}/manageuser`, data, this.header);
  }

  updateNewsletterSubscription(payload): Observable<any> {
    return this.http.post(`${this.url}/newsletter/subscribenewsletter`, payload, this.header);
  }

  unsubscribeFromNewstletter(email: string): Observable<any> {
    return this.http.delete(`${this.url}/newsletter/${email}`, {headers: this.header.headers, responseType: 'text'}, );
  }

  getClientAddresses(companyId): Observable<any> {
    return this.http.get(`${environment.competitionApiUrl}/api/clientAddress/${companyId}`, this.header);
  }

  createClient(payload): Observable<any> {
    return this.http.post(`${environment.competitionApiUrl}/api/client`, payload, this.header);
  }

  createClientAddress(payload): Observable<any> {
    return this.http.post(`${environment.competitionApiUrl}/api/clientAddress`, payload, this.header);
  }
  
  requestAccess(data): Observable<any> {
    return this.http.post(`${this.url}/manageuser/access-request`, data);
  }

  getAccessRequestMessage(data): Observable<any> {
    return this.http.post(`${this.url}/manageuser/access-request-message`, data);
  }

  postAccessRequestAction(data): Observable<any> {
    return this.http.post(`${this.url}/manageuser/access-request-action`, data);
  }
}
