import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from '@mycomp/ui-sdk/cbre-table';


import { SessionConfirmationDialogComponent } from './session-confirmation-dialog/session-confirmation-dialog.component';
import { DeleteUpdateDialogComponent } from './delete-update-dialog/delete-update-dialog.component';
import { MultipleRecordsDialogComponent } from './multiple-records-dialog/multiple-records-dialog.component';
import { DialogComponent } from './dialog/dialog.component';
import { AppErrorComponent } from './error/error.component';
import { FileUploadnDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { ImageUploadDialogComponent } from './image-upload-dialog/image-upload-dialog.component';
import { MassUpdateDialogComponent } from './mass-update-dialog/mass-update-dialog.component';
import { GoogleTagManagerModule } from './modules/google-tag-manager/google-tag-manager.module';
import { NavbarComponent } from './navbar/navbar.component';
import { NoAccessRedirectComponent } from './no-access-redirect/no-access-redirect.component';
import { NoAccessComponent } from './noaccess/no-access.component';
import { RequestAccessDialogComponent } from './noaccess/request-access-dialog/request-access-dialog.component';
import { ApproveAccessDialogComponent } from './noaccess/approve-access-dialog/approve-access-dialog.component';
import { PendingRequestDialogComponent } from './noaccess/pending-request-dialog/pending-request-dialog.component';
import { QuickUpdateDialogComponent } from './quick-update-dialog/quick-update-dialog.component';
import { SnackBarErrorComponent } from './snack-bar-error/snack-bar-error.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { AppMaterialModule } from '../app.material.module';
import { CustomValidatorDirective } from '../common-directives/custom-validator.directive';
import { DirectivesModule } from '../directives/directives.module';
import { InterviewDialogComponent } from '../drive-time/research/interviews/dialog/dialog.component';
import { NavigationItemComponent } from './navbar/navigation-item/navigation-item.component';
import { NotificationDrawerComponent } from './navbar/notification-drawer/notification-drawer.component';
import { LibCoreModule } from 'core';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    AppMaterialModule,
    FontAwesomeModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    GoogleTagManagerModule,
    TableModule,
    LibCoreModule,
  ],
  exports: [NavbarComponent, NoAccessComponent, AppErrorComponent],
  declarations: [
    RequestAccessDialogComponent,
    ApproveAccessDialogComponent,
    PendingRequestDialogComponent,
    NotificationDrawerComponent,
    NavigationItemComponent,
    NavbarComponent,
    SpinnerComponent,
    NoAccessComponent,
    AppErrorComponent,
    NoAccessRedirectComponent,
    DialogComponent,
    InterviewDialogComponent,
    SessionConfirmationDialogComponent,
    MultipleRecordsDialogComponent,
    WarningDialogComponent,
    QuickUpdateDialogComponent,
    MassUpdateDialogComponent,
    DeleteUpdateDialogComponent,
    FileUploadnDialogComponent,
    ImageUploadDialogComponent,
    SnackBarErrorComponent,
    CustomValidatorDirective,
    LogoutComponent,
  ],
})
export class CoreModule {}
