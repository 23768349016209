import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from 'angular-highcharts';
import { LibCoreModule, MaterialModule } from 'core';

import { CustomDateComponent } from './custom-date/custom-date.component';
import { ClickOutsideDirective } from './directives/outsideclick.directive';
import { ToolTipDirective } from './directives/tooltip.directive';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { HighChartsComponent } from './high-charts/high-charts.component';
import { FilterByPipe } from './pipes/filter-by.pipe';
import { SimpleFormComponent } from './simple-form/simple-form.component';




@NgModule({
    declarations: [
        SimpleFormComponent,
        DynamicFieldComponent,
        FilterByPipe,
        ExpansionPanelComponent,
        ToolTipDirective,
        ClickOutsideDirective,
        HighChartsComponent,
        CustomDateComponent,
    ],
    imports: [
        ChartModule,
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        LibCoreModule
    ],
    exports: [
        SimpleFormComponent,
        HighChartsComponent,
        FilterByPipe,
        ExpansionPanelComponent,
    ]
})
export class SharedModule { }
