

enum CompetitionDataTypesEnum {
    STATUS_TYPES= 'StatusTypes',
    SOURCE_TYPES= 'SourceTypes',
    LAST_ACTION_TYPES= 'LastActionTypes',
    OPERCTION_TYPES= 'OperationTypes',
    INDUSTRY_TYPES= 'IndustryTypes',
    NAICS_CODE_TYPES= 'NaicsCodeTypes',
    WAGE_TYPES= 'WageTypes',
    ARTICLE_TOPICS= 'ArticleTopics',
    // this is not a typo, the API returns 'county'
    COUNTY= 'county',
    CITY= 'City',
    COUNTRY= 'Country',
    STATE= 'State',
    CBSA= 'CBSA',
};


export enum InterviewDataTypesEnum {
    INTERVIEWER= 'Interviewer',
    PROJECT= 'Project',
    INTERVIEW_STATUS= 'InterviewStatus',
    CLIENT= 'Client',
    TALENT_SKILL_SET= 'talentSkillSet',
    ORGANIZATION_TYPE= 'OrganizationType',
    INTERVIEW_SCORE_CATEGORIES= 'InterviewScoreCategories'
}


export enum InterviewStaticDataKeyIndexes  {
    INTERVIEWER,
    PROJECT,
    INTERVIEW_STATUS,
    CLIENT,
    TALENT_SKILL_SET,
    ORGANIZATION_TYPE,
    INTERVIEW_SCORE_CATEGORIES
};

export enum CompetitionStaticDataKeyIndexes  {
    STATUS_TYPES,
    SOURCE_TYPES,
    LAST_ACTION_TYPES,
    OPERCTION_TYPES,
    INDUSTRY_TYPES,
    NAICS_CODE_TYPES,
    WAGE_TYPES,
    ARTICLE_TOPICS,
    COUNTY,
    CITY,
    COUNTRY,
    STATE,
    CBSA
};
interface GeoPlace {
    id: number;
    name: string;
    pid: number;
    countryId: number;
    countryISO2: string;
}

export interface City extends GeoPlace {
    country: string
  }

  export interface State {
    id: number;
    name: string;
    countryISO2: string;
    countryId: number;
    pid: number;
    stateAbbr: string;
  }

export interface BasicEntity {
    id: number,
    name: string
}


export interface Client {
    companySize: string;
    id: number;
    industryId: number;
    name: string;
}

export interface DataObject<Type, Data> {
    dataType:Type;
    data: Data[]
}

export type CompetitionStaticDataKeys = keyof CompetitionStaticData;

export type InterviewStaticData = [
    DataObject<InterviewDataTypesEnum.INTERVIEWER, BasicEntity>,
    DataObject<InterviewDataTypesEnum.PROJECT, BasicEntity>,
    DataObject<InterviewDataTypesEnum.INTERVIEW_STATUS, BasicEntity>,
    DataObject<InterviewDataTypesEnum.CLIENT, Client>,
    DataObject<InterviewDataTypesEnum.TALENT_SKILL_SET, BasicEntity>,
    DataObject<InterviewDataTypesEnum.ORGANIZATION_TYPE, BasicEntity>,
    DataObject<InterviewDataTypesEnum.INTERVIEW_SCORE_CATEGORIES, BasicEntity>
]

export type CompetitionStaticData = [
    DataObject<CompetitionDataTypesEnum.STATUS_TYPES, BasicEntity>,
    DataObject<CompetitionDataTypesEnum.SOURCE_TYPES, BasicEntity>,
    DataObject<CompetitionDataTypesEnum.LAST_ACTION_TYPES, BasicEntity>,
    DataObject<CompetitionDataTypesEnum.OPERCTION_TYPES, BasicEntity>,
    DataObject<CompetitionDataTypesEnum.INDUSTRY_TYPES, BasicEntity>,
    DataObject<CompetitionDataTypesEnum.NAICS_CODE_TYPES, BasicEntity>,
    DataObject<CompetitionDataTypesEnum.WAGE_TYPES, BasicEntity>,
    DataObject<CompetitionDataTypesEnum.ARTICLE_TOPICS, BasicEntity>,
    DataObject<CompetitionDataTypesEnum.COUNTY, GeoPlace>,
    DataObject<CompetitionDataTypesEnum.CITY, City>,
    DataObject<CompetitionDataTypesEnum.COUNTRY, BasicEntity>,
    DataObject<CompetitionDataTypesEnum.STATE, State>,
    DataObject<CompetitionDataTypesEnum.CBSA, GeoPlace>
]